import css, { SystemStyleObject } from "@styled-system/css";
import React from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import { SxProp } from "../constants/sx";
import { get } from "../constants/theme";
import useWindowSize from "../hooks/useWindowSize";
import Box from "./Box/Box";

const Wrapper = styled(Box)<SxProp>`

  padding-left: calc(100vw/12 + 5%);
  padding-right: calc(100vw/12);
  max-width: 2560px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding: 0;
  }


  ${(props) => props.sx && css({ ...props.sx })};
`;





export default React.memo(Wrapper);