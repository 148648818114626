import { SystemStyleObject } from "@styled-system/css";
import { Link } from "gatsby";
import React from "react";
import Arrow from "../../assets/link.inline.svg";
import { get } from "../constants/theme";
import { clampBuilder } from "../helpers/sizeCalc";
import { Routes } from "../routes";
import Box from "./Box/Box";

type Props = {
  to: Routes | string;
  title: string;
  as: React.ComponentType<any> | keyof HTMLElementTagNameMap;
  sx?: SystemStyleObject;
  disabled?: boolean;
};

const LinkArrowBtn = ({ to, title, as, sx, disabled = false }: Props) => {
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (disabled) e.preventDefault();
    },
    [disabled]
  );
  return (
    <Box
      onClick={handleClick}
      as={Link}
      to={to}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        marginTop: "80px",
        marginBottom: "80px",
        userSelect: "none",
        transition: "opacity .3s linear",
        gap: ".75rem",
        opacity: disabled ? 0.5 : 1,
        ":hover": {
          opacity: 0.75,
        },
        ...sx,
      }}
    >
      <Box as={Arrow} />
      <Box
        as="span"
        sx={{
          fontWeight: 400,
          fontFamily: "custom_42264",
          fontSize: [
            clampBuilder(320, 599, 1.3125, 2.375),
            clampBuilder(599, 1680, 1.125, 2.25),
            clampBuilder(599, 1680, 1.125, 2.25),
            clampBuilder(599, 1680, 1.125, 2.25),
            clampBuilder(599, 1680, 1.125, 2.25),

            //"36px",
            //"18px",
          ],
          lineHeight: "32px",
          textTransform: "uppercase",
          color: get("palette.text.link_btn"),
          paddingTop: "0.2em",
        }}
      >
        {title}
      </Box>
    </Box>
  );
};

export default React.memo(LinkArrowBtn);
