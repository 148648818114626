import css, { SystemStyleObject } from "@styled-system/css";
import React from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import { SxProp } from "../constants/sx";
import { get } from "../constants/theme";
import useWindowSize from "../hooks/useWindowSize";
import Box from "./Box/Box";

export type GridSectionFirstChildrenType =
  | "textBox"
  | "imageBox"
  | "topImageBox"
  | "topLeftImageBox";

export type GridSectionSecondChildrenType =
  | "textBox"
  | "imageBox"
  | "bottomTextBox"
  | "bottomRightTextBox";

type Props = {
  firstChildrenType: GridSectionFirstChildrenType;
  secondChildrenType: GridSectionSecondChildrenType;
  children: [React.ReactNode, React.ReactNode];
  mainContainerSx?: SystemStyleObject;
  firstChildrenWrapperSx?: SystemStyleObject;
  secondChildrenWrapperSx?: SystemStyleObject;
};

const GridContainer = styled(Box)<SxProp & { containerMinHeight?: number }>`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  /* min-height: ${(props) =>
    props.containerMinHeight ? `${props.containerMinHeight}px` : "100vh"}; */
  width: 100%;
  & > :nth-child(1n) {
    flex: 1;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    margin-top: 100px;
  }

  ${(props) => props.sx && css({ ...props.sx })};
`;

const FirstChildrenWrapper = styled(Box)<
  SxProp & { variant: GridSectionFirstChildrenType }
>`
  width: 100%;

  ${variant({
    variants: {
      textBox: {
        display: "flex",
        gridColumnStart: [1, 1, 1, 1],
        gridColumnEnd: [13, 7, 7, 7],
        gridRowStart: [1, 1, 1, 1],
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 400,
        fontFamily: "custom_42264",
        color: get("palette.text.brown"),
        textAlign: ["left", "left", "left", "left"],
        fontSize: "1.5vmax",
        width: "100%",
        marginTop: ["20px", 0, 0, 0],
        marginBottom: ["20px", 0, 0, 0],
      },
      imageBox: {
        gridColumnStart: [1, 1, 1, 1],
        gridColumnEnd: [13, 6, 6, 6],
      },
      topImageBox: {
        width: "100%",
        marginTop: [0, "40px", "60px", "80px"],
        gridColumnStart: [1, 5, 5, 5],
        gridColumnEnd: [13, 12, 12, 12],
        gridRowStart: [2, 1, 1, 1],
        marginBottom: [0, "40px", "60px", "80px"],
      },
      topLeftImageBox: {
        width: "100%",
        gridColumnStart: [1, 2, 2, 2],
        gridColumnEnd: [13, 8, 8, 8],
        gridRowStart: [2, 1, 1, 1],
        marginBottom: [0, "40px", "60px", "80px"],
      },
    },
  })}
  ${(props) => props.sx && css({ ...props.sx })};
`;

const SecondChildrenWrapper = styled(Box)<
  SxProp & { variant: GridSectionSecondChildrenType }
>`
  width: 100%;
  ${variant({
    variants: {
      textBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontWeight: 400,
        fontFamily: "custom_42264",
        color: get("palette.text.brown"),
        textAlign: ["left", "left", "left", "left"],
        fontSize: "1.5vmax",
        gridColumnStart: [1, 7, 7, 7],
        gridColumnEnd: [13, 13, 13, 13],
        gridRowStart: [1, 1, 1, 1],
        marginTop: ["20px", 0, 0, 0],
        marginBottom: ["20px", 0, 0, 0],
      },
      imageBox: {
        marginBottom: "20px",
        gridColumnStart: [1, 8, 8, 8],
        gridColumnEnd: [13, 13, 13, 13],
        gridRowStart: [2, 1, 1, 1],
      },
      bottomTextBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontWeight: 400,
        fontFamily: "custom_42264",
        color: get("palette.text.brown"),
        textAlign: ["left", "left", "left", "left"],
        fontSize: "1.5vmax",
        gridColumnStart: [1, 1, 1, 1],
        gridColumnEnd: [13, 7, 7, 7],
        gridRowStart: [1, 2, 2, 2],
        marginTop: ["20px", 0, 0, 0],
        marginBottom: "20px",
      },
      bottomRightTextBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontWeight: 400,
        fontFamily: "custom_42264",
        color: get("palette.text.brown"),
        textAlign: ["left", "left", "left", "left"],
        fontSize: "1.5vmax",
        gridColumnStart: [1, 7, 7, 7],
        gridColumnEnd: [13, 13, 13, 13],
        gridRowStart: [1, 2, 2, 2],
        marginTop: ["20px", 0, 0, 0],
        marginBottom: "20px",
      },
    },
  })}

  ${(props) => props.sx && css({ ...props.sx })};
`;

const GridSection = ({
  firstChildrenType,
  secondChildrenType,
  children,
  mainContainerSx,
  firstChildrenWrapperSx,
  secondChildrenWrapperSx,
}: Props) => {
  const { height } = useWindowSize();

  return (
    <GridContainer containerMinHeight={height} sx={{ ...mainContainerSx }}>
      <FirstChildrenWrapper
        variant={firstChildrenType}
        sx={{ ...firstChildrenWrapperSx }}
      >
        {children[0]}
      </FirstChildrenWrapper>
      <SecondChildrenWrapper
        variant={secondChildrenType}
        sx={{ ...secondChildrenWrapperSx }}
      >
        {children[1]}
      </SecondChildrenWrapper>
    </GridContainer>
  );
};

export default React.memo(GridSection);
